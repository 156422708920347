









































































































































































































































































































.container {
	background-color: #f8f8f8;
	height: calc(100vh + 110px);
}
.user_msg {
	background-color: #ffffff;
	margin: 10px;
	border-radius: 8px;
	box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
	font-family: PingFang SC;
	.content-top {
		margin: 0 0.4rem;
		.all_cout {
			display: flex;
			padding: 0.24rem 0;
			.con_left {
				color: #5899f6;
				font-size: 0.3rem;
				font-weight: 500;
			}
			.con_right {
				color: #5899f6;
				font-size: 0.3rem;
				font-weight: 500;
				margin-left: 0.28rem;
			}
		}
	}
	.content {
		margin: 0 0.4rem;
		padding: 0.12rem 0 0.38rem 0;
		.list {
			display: flex;
			align-items: center;
			padding: 0.08rem 0;
			.con_left {
				width: 30%;
				color: #333333;
				font-size: 0.3rem;
				font-weight: 500;
				text-align: left;
			}
			.con_left1 {
				color: #5899f6;
				font-size: 0.24rem;
				font-weight: 500;
				text-align: left;
			}
			.con_right {
				width: 70%;
				color: #666666;
				font-size: 0.28rem;
				margin-left: 0.28rem;
			}
			.con_right1 {
				color: #5899f6;
				font-size: 0.24rem;
			}
			.right-jingxin {
				color: #13ad8f;
			}
			.right-shenhe {
				color: #d98181;
			}
		}
		.list0 {
			display: flex;
			justify-content: space-between;
			border-bottom: 0.01rem solid #999999;
			align-items: center;
			padding: 0.1rem 0;
			.con_left {
				color: #333333;
				font-size: 0.3rem;
				font-weight: bold;
				text-align: left;
			}
			.con_right {
				color: #666666;
				font-size: 0.28rem;
				margin-left: 0.28rem;
			}
			.right-jingxin {
				color: #13ad8f;
			}
			.right-shenhe {
				color: #d98181;
			}
		}
		.list3 {
			padding: 0.1rem 0;
			.con_left {
				color: #333333;
				font-size: 0.3rem;
				font-weight: bold;
				text-align: left;
			}
		}
		.list4 {
			display: flex;
			align-items: center;
			padding-bottom: 0.16rem;
			border-bottom: 0.01rem solid #8b9fad;
			.con_left1 {
				color: #5899f6;
				font-size: 0.24rem;
				font-weight: 500;
				text-align: left;
			}
			.con_right1 {
				color: #5899f6;
				font-size: 0.24rem;
			}
		}
		.list5 {
			display: flex;
			align-items: center;
			padding: 0.16rem 0;
			.dq {
				color: #8b9fad;
				font-size: 0.28rem;
				font-weight: 500;
				text-align: center;
			}
			.ls {
				margin-left: 0.46rem;
				color: #8b9fad;
				font-size: 0.28rem;
				font-weight: 500;
				text-align: center;
			}
			.active {
				color: #5899f6;
				font-size: 0.3rem;
			}
		}
		.list6 {
			margin-top: 0.46rem;
			display: flex;
			justify-content: space-between;
			padding-bottom: 0.3rem;
			border-bottom: 0.01rem solid #8b9fad;
			.left_user {
				display: flex;
				flex-direction: column;
				align-items: center;
				.img {
					width: 0.8rem;
					height: 0.8rem;
					img {
						border-radius: 0.5rem;
						width: 100%;
						height: 100%;
					}
				}
				.name {
					margin-top: 0.26rem;
					color: #333333;
					font-weight: 500;
					font-size: 0.28rem;
				}
			}
			.msg-center {
				// margin-left: -0.3rem;
				.msg-item {
					margin-top: 0.08rem;
					display: flex;
					align-items: center;
					justify-content: space-between;
					.mag-left {
						color: #333333;
						font-size: 0.3rem;
						font-weight: 500;
						text-align: left;
					}
					.msg-right {
						margin-left: 0.08rem;
						color: #666666;
						font-size: 0.28rem;
					}
				}
				.msg-item:first-child {
					margin-top: 0;
				}
				.msg-item:last-child {
					margin-top: 0.18rem;
				}
			}
			.msg-last {
				margin-top: 0.7rem;
				.msg-right-top {
					text-align: right;
					font-size: 0.3rem;
					font-weight: bold;
					color: #d98181;
				}
				.msg-right-bottom {
					text-align: center;
					font-size: 0.24rem;
					font-weight: bold;
					color: #d98181;
				}
			}
		}
		.list6:last-child {
			border-bottom: none;
		}
		.button {
			margin: 0 auto;
			margin-top: 0.3rem;
			width: 6rem;
			height: 0.76rem;
			background-color: #5899f6;
			color: #ffffff;
			line-height: 0.76rem;
			border-radius: 0.16rem;
			text-align: center;
			font-size: 0.28rem;
		}
	}
}
.bottom {
	position: fixed;
	left: 0;
	bottom: 0;
	background-color: #ffffff;
	padding: 0;
	.button {
		margin: 0.14rem 0.3rem 0 0.28rem;
		padding: 0.22rem 2.82rem;
		background-color: #5899f6;
		color: #ffffff;
		font-size: 0.32rem;
		text-align: center;
		border-radius: 0.12rem;
		font-weight: 500;
	}
}
.tan {
	width: 5.6rem;
	height: 3.6rem;
	background-image: url(../../assets/imgs/tan_bg.png);
	background-repeat: no-repeat;
	background-size: 100% 3.6rem;
	.tan_no_txt1 {
		padding: 1.4rem 0.82rem 0 0.72rem;
		color: #333333;
		font-size: 0.28rem;
		text-align: center;
		font-weight: 500;
		color: #333333;
	}
	.tan_no_txt2 {
		margin-top: 0.05rem;
		color: #333333;
		font-size: 0.24rem;
		transform: scale(0.75);
		font-weight: 500;
		color: #666666;
		text-align: center;
		.right {
			color: #d98181;
		}
	}
	.tan_no_button {
		margin: 0 1.02rem;
		margin-top: 0.5rem;
		display: flex;
		justify-content: space-between;
		.yes {
			color: #5899f6;
			padding: 0.08rem 0.32rem;
			border: 1px solid #5899f6;
			border-radius: 0.16rem;
			background-color: #ffffff;
		}
		.no {
			color: #ffffff;
			padding: 0.1rem 0.22rem 0.08rem 0.24rem;
			border-radius: 0.16rem;
			background-color: #5899f6;
		}
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;